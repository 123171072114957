import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '41, 76, 132',
		'primary-dark': '21, 48, 95',
		'accent': '238, 212, 121',
		'accent-plus': '21, 48, 95',
	},
});
